/**
 * Created by flo on 2/17/16.
 */
function floSendMail( form, container){
  jQuery('#flo-name').removeClass('invalid');
  jQuery('#flo-email').removeClass('invalid');

  jQuery(container).html('');

  //jQuery('#flo-loading').css('background','rgba(255,255,255,0.2)');
  //jQuery('#flo-loading').fadeIn('slow'); // loading effect
  jQuery.ajax({
    url: ajaxurl,
    data: '&action=floSendContact&'+jQuery( form ).serialize(),
    type: 'POST',
    dataType: "json",
//      cache: false,
    success: function (json) {

      //jQuery('#flo-loading').fadeOut('slow'); // loading effect

      if(json.contact_name ){
        jQuery('#flo-name').addClass('invalid');
        jQuery(container).append(json.contact_name);
      }

      if(json.contact_name ){
        jQuery('#location').addClass('invalid');
        jQuery(container).append(json.contact_location);
      }

      if(json.contact_name ){
        jQuery('#date').addClass('invalid');
        jQuery(container).append(json.contact_date);
      }
      if(json.contact_name ){
        jQuery('#question').addClass('invalid');
        jQuery(container).append(json.contact_question);
      }

      if(json.contact_email ){
        jQuery('#flo-email').addClass('invalid');
        jQuery(container).append(json.contact_email);
      }

      if(json.message ){
        jQuery('.flo-modal').fadeIn('slow');

        jQuery( form).find('input[type="text"], textarea').val('');

        setTimeout(function(){
          jQuery('.flo-modal').fadeOut('slow');
        },3000);
      }

    }
  });
}
(function(window, $, undefined){
  "use strict";

  var breakpoint_small = 750,
      breakpoint_medium = 1024;

  var isDesktop = function() {
    return jQuery(document).width() > breakpoint_medium;
  };

  var isTablet = function() {
    return jQuery(document).width() <= breakpoint_medium && jQuery(document).width() > breakpoint_small;
  };

  var isMobile = function() {
    return jQuery(document).width() <= breakpoint_small;
  };

  var rem = function() {
    return parseInt($("html").css("font-size"), 10);
  }

  $(document).foundation();

  // var floConfig = {
  //   page_padding: 50
  // };

  var resizeEnded_timeout;
  window.onresize = function(){
    clearTimeout(resizeEnded_timeout);
    resizeEnded_timeout = setTimeout(function(){
      $(window).trigger("resizeEnded");
    }, 100);
  };

  $(function() {
    FastClick.attach(document.body);
  });
$(document).ready(function(){
if (typeof floConfig !== "undefined")
  if (floConfig.automatically_text_color == "1"){
    var 
    $slick = $(".flo_slider .slick"),
    BackgroundCheck_targets = [
      ".slick-current .slide_content",

      ".flo_slider .slick-dots",
      ".flo_slider .slick-next",
      ".flo_slider .slick-prev",

      ".flo_header .flo_logo",
      ".flo_header .flo_header_addons",
      ".flo_header .flo_header_menu",
      ".flo_header .right_side",
      ".flo_header .left_side"
    ].join(", "),
    // Note: If the background is dark the element needs to be light
    BackgroundCheck_classes = { 
      dark: 'dark',
      light: 'light',
      complex: 'complex'
    }
    ;

    $slick.on("afterChange", function(){
      var $slider = $slick.parent();

      // Decide on which images to check for brightness
      var images = function($slider){
        if (isDesktop() || isTablet()) {
          if ($slider.hasClass("type_single") || $slider.hasClass("type_contain"))
            return ".slick-current"

          else 

          if ($slider.hasClass("type_visible_nearby"))
            return ".slide > img"

          else 
            return ""
        } else if (isMobile()){
          return ".slide > img"
        }
      }

      $(BackgroundCheck_targets).removeClass("dark").removeClass("light");
      BackgroundCheck.init({
        targets: BackgroundCheck_targets,
        images: images($slider),
        classes: BackgroundCheck_classes,
        windowEvents: false
      });
      BackgroundCheck.refresh();
      
    });


    var 
    first_slide_check_interval_count = 0,
    first_slide_check_interval = setInterval(function(){
      $slick.trigger("afterChange");
      if (first_slide_check_interval_count == 5) {
        clearInterval(first_slide_check_interval);
      }
      first_slide_check_interval_count++; 
    }, 500);

  }
});})(window, jQuery.noConflict());