/**
 * Created by flo on 2/17/16.
 */
function floSendMail( form, container){
  jQuery('#flo-name').removeClass('invalid');
  jQuery('#flo-email').removeClass('invalid');

  jQuery(container).html('');

  //jQuery('#flo-loading').css('background','rgba(255,255,255,0.2)');
  //jQuery('#flo-loading').fadeIn('slow'); // loading effect
  jQuery.ajax({
    url: ajaxurl,
    data: '&action=floSendContact&'+jQuery( form ).serialize(),
    type: 'POST',
    dataType: "json",
//      cache: false,
    success: function (json) {

      //jQuery('#flo-loading').fadeOut('slow'); // loading effect

      if(json.contact_name ){
        jQuery('#flo-name').addClass('invalid');
        jQuery(container).append(json.contact_name);
      }

      if(json.contact_name ){
        jQuery('#location').addClass('invalid');
        jQuery(container).append(json.contact_location);
      }

      if(json.contact_name ){
        jQuery('#date').addClass('invalid');
        jQuery(container).append(json.contact_date);
      }
      if(json.contact_name ){
        jQuery('#question').addClass('invalid');
        jQuery(container).append(json.contact_question);
      }

      if(json.contact_email ){
        jQuery('#flo-email').addClass('invalid');
        jQuery(container).append(json.contact_email);
      }

      if(json.message ){
        jQuery('.flo-modal').fadeIn('slow');

        jQuery( form).find('input[type="text"], textarea').val('');

        setTimeout(function(){
          jQuery('.flo-modal').fadeOut('slow');
        },3000);
      }

    }
  });
}
(function(window, $, undefined){
  "use strict";

  var breakpoint_small = 750,
      breakpoint_medium = 1024;

  var isDesktop = function() {
    return jQuery(document).width() > breakpoint_medium;
  };

  var isTablet = function() {
    return jQuery(document).width() <= breakpoint_medium && jQuery(document).width() > breakpoint_small;
  };

  var isMobile = function() {
    return jQuery(document).width() <= breakpoint_small;
  };

  var rem = function() {
    return parseInt($("html").css("font-size"), 10);
  }

  $(document).foundation();

  // var floConfig = {
  //   page_padding: 50
  // };

  var resizeEnded_timeout;
  window.onresize = function(){
    clearTimeout(resizeEnded_timeout);
    resizeEnded_timeout = setTimeout(function(){
      $(window).trigger("resizeEnded");
    }, 100);
  };

  $(function() {
    FastClick.attach(document.body);
  });
/* SEARCH START */
$(document).ready(function(){
  var
  $search_toggle = $(".flo_header .search_toggle"),
  $toggle = $search_toggle.children("i"),
  $wrap = $search_toggle.children(".search_input_wrap"),
  $input = $wrap.children(".search_input"),
  $submit = $wrap.children(".search_submit"),
  $close = $wrap.children(".search_close"),
  show_class = "show",
  show_search = function() {
    $wrap.addClass(show_class);
    MotionUI.animateIn($wrap, "fade-in");
  },
  hide_search = function() {
    $wrap.removeClass(show_class);
    MotionUI.animateOut($wrap, "fade-out");
  }
  ;
  $toggle.on("click", function(){
    if ($wrap.hasClass(show_class) == false) {
      show_search();
    } else
    if ($wrap.hasClass(show_class)) {
      hide_search();
    }
  });

  // Hide search on escape
  $(document).keyup(function(e) {
    if (e.keyCode == 27) {
      hide_search();    
    }
  });
});
/* SEARCH END */})(window, jQuery.noConflict());